import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import SlickSlider from "./Slick";

export default function Agenda({ event }) {
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="Agenda" id="Agenda">
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="April 17" {...a11yProps(0)} />
          <Tab label="April 18" {...a11yProps(1)} />
        </Tabs>
      </Box> */}
      <CustomTabPanel value={value} index={0}>
        {
          <>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
              Smart Education Implications For Saudi Arabia
            </Typography>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                  Reception
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  9:30-10:00
                </Typography>
              </AccordionSummary>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                  Opening Remarks
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  10:00-10:10
                </Typography>
              </AccordionSummary>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                  Opportunities in Smart Education
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  10:10-10:40
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>Context: </b>
                  Including technology-aided classrooms, individualized learning
                  experiences and classroom integration.
                </Typography>
                {/* <Typography>
                  <b>Objectives:</b>
                  <ol>
                    <li>
                      1. Identify the current landscape of quantum research,
                      development, and investment in KSA.
                    </li>
                    <li>
                      Explore the potential to revolutionize various sectors,
                      from computing to communication to position KSA at the
                      forefront of this transformative field.
                    </li>
                    <li>
                      Discuss strategies for fostering collaboration among
                      government, industry, technology companies, and academia
                      to accelerate quantum innovation.
                    </li>
                    <li>
                      Raise awareness about the significance of quantum science
                      and its potential applications in KSA.
                    </li>
                    <li>
                      Explore the role of policies and regulations in supporting
                      growth of a quantum ecosystem in KSA.
                    </li>
                  </ol>
                </Typography> */}
                {/* <Typography>
                  <b>Speakers:</b>
                  <ol>
                    <li>
                      <b>Haitham AlFaraj</b>, CTO, stc{" "}
                    </li>
                    <li>
                      <b>Dr. Talal Alsedairy</b>, Senior Vice President R&D,
                      KACST{" "}
                    </li>
                    <li>
                      <b>Simon Patkovic</b>, VP Quantum Safe Solutions,
                      IDQuantique{" "}
                    </li>
                    <li>
                      <b>Meshal AlMashari</b>, Director, Digital Strategy &
                      Investment Department, Saudi Aramco
                    </li>
                  </ol>
                </Typography>
                <Typography>
                  <b> Moderator: </b>
                  <ul>
                    <li>
                      <b>Hani AlHemsi</b>, Acting Director of Technology Growth
                      Research and Studies Expert, MCIT
                    </li>
                  </ul>
                </Typography> */}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                  Challenges and Risks
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  10:40-11:10
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>Context: </b>
                  Focusing on inequality in access to smart education, the need
                  for regionalized curricula and resistance to adoption.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                  Recommendations for Smart Education
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  11:10-11:25
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>Context: </b>
                  Insight on how to safely implement, maintain integrity in fair
                  assessment and regulation or tailored AI solutions per grade
                  level.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Closing Remarks 
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  11:25-11:30
                </Typography>
              </AccordionSummary>
            </Accordion>

            <Typography variant="h5" sx={{ my: 2, mt: 6, fontWeight: "bold" }}>
              Speakers
            </Typography>
            <SlickSlider event="main" />
          </>
        }
      </CustomTabPanel>
    </div>
  );
}
